import React, { useState } from 'react';
import './Login.css';  // Import your custom CSS file

const Login = () => {
  // State to manage form input values
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      // Retrieve user data from localStorage
      const storedUser = JSON.parse(localStorage.getItem('accountInfo'));
      
      if (storedUser) {
        // Check if the entered email and password match
        if (storedUser.email === email) {
          if (storedUser.password === password) {
            alert('Login successful!');
            window.location.href = 'main.html';
          } else {
            setPasswordError('Incorrect password');
            setEmailError(''); // Clear email error
          }
        } else {
          setEmailError('Invalid user. Please sign up.');
          setPasswordError(''); // Clear password error
        }
      } else {
        setEmailError('No account found. Please sign up.');
        setPasswordError(''); // Clear password error
      }
    }
  };

  // Form validation
  const validateForm = () => {
    let isValid = true;

    // Clear previous error messages
    setEmailError('');
    setPasswordError('');

    // Email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(gmail|yahoo)\.com$/;
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address with a correct domain (e.g., gmail.com).');
      isValid = false;
    }

    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError('Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.');
      isValid = false;
    }

    return isValid;
  };

  return (
    <section className="login-section">
      <div className="image-container">
        <img
          src="https://img.freepik.com/premium-photo/portrait-handsome-young-man-with-shopping-bags_220507-19441.jpg"
          alt="Welcome Back"
          className="welcome-image"
        />
      </div>
      <div className="form-container">
        <img
          src="BLAS BLUSH.png"
          alt="Company Logo"
          className="company-logo"
        />
        <h2>Welcome Back</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">
              <i className="fa fa-envelope"></i> Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <small className="error-message">{emailError}</small>
          </div>
          <div className="form-group">
            <label htmlFor="password">
              <i className="fa fa-lock"></i> Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p className="forgot-password">
              <a href="#">Forgot password?</a>
            </p>
            <small className="error-message">{passwordError}</small>
          </div>
          <div className="form-group">
            <button type="submit" className="login-button">Login</button>
            <hr className="divider" />
          </div>
          <div className="social-login">
            <p>Or log in with:</p>
            <div className="social-buttons">
              <button className="social-button google">
                <i className="fab fa-google"></i> Google
              </button>
              <button className="social-button facebook">
                <i className="fab fa-facebook-f"></i> Facebook
              </button>
            </div>
          </div>
          <div className="form-options">
            <p>
              Don't have an account? <a href="signup.html">Sign up</a>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
