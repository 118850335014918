import React, { useEffect, useState } from "react";

function OrderSummary({ totalMRP, totalDiscount, shippingCost, totalAmount }) {
  return (
    <div className="order-summary-container">
      <p>Price: ₹ {totalMRP.toFixed(2)}</p>
      <p>Discount: ₹ -{totalDiscount.toFixed(2)}</p>
      <p>Shipping Cost: ₹ {totalMRP >= 500 ? 'Free' : `₹${shippingCost.toFixed(2)}`}</p>
      <h3>Total Amount: ₹ {totalAmount.toFixed(2)}</h3>
    </div>
  );
}

function ParentComponent() {
  const [cartItems, setCartItems] = useState([]);
  const [totalMRP, setTotalMRP] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [shippingCost] = useState(50); // Fixed shipping cost if MRP < 500
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Retrieve cart items from localStorage and set the state
    const cartData = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(cartData);
    calculateTotals(cartData);
  }, [shippingCost]);

  const calculateTotals = (cartData) => {
    let calculatedMRP = 0;
    let calculatedDiscount = 0;

    cartData.forEach(item => {
      // Ensure mainPrice and discountPrice are strings, then remove any 'Rs.' prefix and convert to float
      const mainPrice = item.mainPrice ? parseFloat(item.mainPrice.toString().replace('Rs.', '')) : 0;
      const discountPrice = item.discountPrice ? parseFloat(item.discountPrice.toString().replace('Rs.', '')) : mainPrice;

      calculatedMRP += mainPrice * item.quantity;
      calculatedDiscount += (mainPrice - discountPrice) * item.quantity;
    });

    setTotalMRP(calculatedMRP);
    setTotalDiscount(calculatedDiscount);

    const calculatedTotalAmount = calculatedMRP - calculatedDiscount + (calculatedMRP >= 500 ? 0 : shippingCost);
    setTotalAmount(calculatedTotalAmount);
  };

  const handleProceed = () => {
    window.location.href = "/checkout";
  };

  return (
    <div>
      <OrderSummary 
        totalMRP={totalMRP} 
        totalDiscount={totalDiscount} 
        shippingCost={shippingCost}
        totalAmount={totalAmount} 
      />
      
      {/* Proceed button below the summary */}
      <button onClick={handleProceed} className="proceed-button">
        Proceed
      </button>
    </div>
  );
}

export default ParentComponent;
