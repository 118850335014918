import React, { useEffect, useState } from 'react';

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    try {
      // Retrieve cart items from localStorage and ensure quantity is set
      const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const itemsWithQuantity = storedItems.map(item => ({
        id: item.id,
        image: item.image || '', // Provide a default image URL if missing
        title: item.title || 'No title available', // Default title if missing
        mainPrice: String(item.mainPrice || '0'), // Ensure mainPrice is a string
        discountPrice: String(item.discountPrice || '0'), // Ensure discountPrice is a string
        discount: String(item.discount || '0'), // Ensure discount is a string
        quantity: item.quantity || 1, // Ensure quantity is set
      }));
      setCartItems(itemsWithQuantity);
    } catch (error) {
      console.error("Error parsing cart items from localStorage:", error);
      setCartItems([]); // Set to empty array on error
    }
  }, []);

  const updateLocalStorage = (updatedCart) => {
    // Update the cart in localStorage and state
    localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const handleRemoveProduct = (id) => {
    // Remove the product from the cart
    const updatedCart = cartItems.filter(item => item.id !== id);
    updateLocalStorage(updatedCart);
  };

  const handleIncrementQuantity = (id) => {
    const updatedCart = cartItems.map(item => {
      if (item.id === id) {
        // Increase quantity of the item
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    updateLocalStorage(updatedCart);
  };

  const handleDecrementQuantity = (id) => {
    const updatedCart = cartItems.map(item => {
      if (item.id === id && item.quantity > 1) {
        // Decrease quantity of the item, but not below 1
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    updateLocalStorage(updatedCart);
  };

  return (
    <div className="checkout-page">
      <p className="item-summary-h1">Item Summary</p>
      {cartItems.length > 0 ? (
        cartItems.map(product => (
          <div key={product.id} className="product-item-details">
            <img src={product.image} alt={product.title} />
            <h3>{product.title}</h3>
            <p>Price: ₹{product.mainPrice}</p>
            <p>Discount Price: ₹{product.discountPrice}</p>
            <p>Discount: ₹{product.discount}</p>
            <div className="quantity-controls">
              <button onClick={() => handleDecrementQuantity(product.id)}>-</button>
              <span>Quantity: {product.quantity}</span>
              <button onClick={() => handleIncrementQuantity(product.id)}>+</button>
            </div>
            <button 
              onClick={() => handleRemoveProduct(product.id)} 
              className="remove-button"
            >
              🗑️ Remove Item
            </button>
          </div>
        ))
      ) : (
        <p>No products in the cart</p>
      )}
    </div>
  );
};

export default Checkout;
