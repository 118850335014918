import React from "react";
import "./ProductPage.css";

const products = [
 {
    id: 1,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/5/7/v/3xl-formal-shirt-metronaut-original-imah2hqncjyyqead.jpeg?q=70&crop=false",
  },{
    id: 2,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/x/q/0/xl-frml-st2-vebnor-original-imah4tvcbttftvuf.jpeg?q=70&crop=false",
  },{
    id: 3,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/z/k/w/m-r-pink-stoneberg-original-imah555sqze7atry.jpeg?q=70&crop=false",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/m/q/3/m-r-peacock-blue-stoneberg-original-imah5f7wrynbprrd.jpeg?q=70&crop=false",
  },{
    id: 5,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/i/j/c/2xl-csrmnkt-white-voroxy-original-imahfmcgg7vggdwm.jpeg?q=70&crop=false",
  },{
    id: 6,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/v/4/0/-original-imagqfvhqu2gmtjg.jpeg?q=70&crop=false",
  },{
    id: 7,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/a/2/i/-original-imaghcphhjfmmcxv.jpeg?q=70&crop=false",
  },{
    id: 8,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/p/s/s/48-lpsfmclpx31942-louis-philippe-original-imah34rrxr5mfvvu.jpeg?q=70&crop=false",
  },
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="Formal">
        <div className="hero-content">
          <h1>Class Meets Comfort – Perfect Formal Attire for Any Occasion.</h1>
          <p>Redefine Your Professional Look with Style.</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

      <div className="product-section">
        {products.map((product) => (
          <div key={product.id} className="product">
            <div className="product-link">
              <img src={product.image} alt={product.title} className="product-image" />
              <h4 className="product-title">{product.title}</h4>
              <h3 className="sub-title">{product.subtitle}</h3>
              <div className="price">
                <h3 className="main-price">
                  {product.discountPrice} <span className="d-price">{product.mainPrice}</span>{" "}
                  <span className="s-discount">{product.discount}</span>
                </h3>
              </div>
            </div>
            <div className="button-group">
              <button className="cart-button add-to-cart-btn">Add To Cart</button>
              <button className="cart-button buy-now-btn">Buy Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
