import React, { useState } from 'react';
import './paymentsection.css'; // Make sure to import your CSS

const PaymentSection = () => {
  const [selectedPayment, setSelectedPayment] = useState('cod'); // Default payment method is 'Cash on Delivery'
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log({
      selectedPayment,
      cardNumber,
      expiryDate,
      cvv,
    });
  };

  return (
    <section className="payment-section">
      <h3>Payment Options</h3>
      <div className="option">
        <div>
          <input
            type="radio"
            id="cod"
            name="payment"
            checked={selectedPayment === 'cod'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="cod">Cash on Delivery</label>
          {selectedPayment === 'cod' && (
            <div className="payment-details">You will pay in cash when the product is delivered.</div>
          )}
        </div>
        <div>
          <input
            type="radio"
            id="credit-card"
            name="payment"
            checked={selectedPayment === 'credit-card'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="credit-card">Credit Card</label>
          {selectedPayment === 'credit-card' && (
            <div className="payment-details">
              <h4>Enter Credit Card Details</h4>
              <label htmlFor="card-number">Card Number</label>
              <input
                type="text"
                id="card-number"
                placeholder="Enter your card number"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
              <hr></hr>
              <label htmlFor="expiry-date">Expiry Date</label>
              <input
                type="text"
                id="expiry-date"
                placeholder="MM/YY"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
                          <label htmlFor="cvv">              CVV</label>
              <input
                type="text"
                id="cvv"
                placeholder="Enter CVV"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </div>
          )}
        </div>
        <div>
          <input
            type="radio"
            id="debit-card"
            name="payment"
            checked={selectedPayment === 'debit-card'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="debit-card">Debit Card</label>
          {selectedPayment === 'debit-card' && (
            <div className="payment-details">
              <h4>Enter Debit Card Details</h4>
              <label htmlFor="card-number">Card Number</label>
              <input
                type="text"
                id="card-number"
                placeholder="Enter your card number"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
              <label htmlFor="expiry-date">Expiry Date</label>
              <input
                type="text"
                id="expiry-date"
                placeholder="MM/YY"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
              <label htmlFor="cvv">CVV</label>
              <input
                type="text"
                id="cvv"
                placeholder="Enter CVV"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </div>
          )}
        </div>
        <div>
          <input
            type="radio"
            id="emi"
            name="payment"
            checked={selectedPayment === 'emi'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="emi">EMI</label>
          {selectedPayment === 'emi' && (
            <div className="payment-details">Select an EMI plan for payment.</div>
          )}
        </div>
        <div>
          <input
            type="radio"
            id="upi"
            name="payment"
            checked={selectedPayment === 'upi'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="upi">UPI</label>
          {selectedPayment === 'upi' && (
            <div className="payment-details">Enter your UPI ID to proceed with payment.</div>
          )}
        </div>
        <div>
          <input
            type="radio"
            id="wallets"
            name="payment"
            checked={selectedPayment === 'wallets'}
            onChange={handlePaymentChange}
          />
          <label htmlFor="wallets">Wallets</label>
          {selectedPayment === 'wallets' && (
            <div className="payment-details">Select your wallet for payment.</div>
          )}
        </div>
      </div>

      {/* Show submit button only if a valid payment option is selected */}
      {(selectedPayment === 'credit-card' || selectedPayment === 'debit-card') && (
        <button className="submit-btn" onClick={handleSubmit}>Submit Payment</button>
      )}
    </section>
  );
};

export default PaymentSection;
