import React, { useEffect, useRef, useState } from 'react';
import './Mirror.css';
import Header from './Header';

const CameraAccess = () => {
    const [isCameraOn, setIsCameraOn] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);

    // Function to start the camera
    const startCamera = async () => {
        try {
            const localStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(localStream);
            videoRef.current.srcObject = localStream;
            setIsCameraOn(true);
        } catch (error) {
            console.error('Error accessing the camera:', error);
            alert('Unable to access the camera. Error: ' + error.message);
        }
    };

    // Function to stop the camera
    const stopCamera = () => {
        if (stream) {
            // Stop all video tracks to release the camera
            stream.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
            setIsCameraOn(false);
        }
    };

    // Toggle camera function
    const toggleCamera = () => {
        if (isCameraOn) {
            stopCamera();
        } else {
            startCamera();
        }
    };

    // Function to capture the image
    const captureImage = () => {
        if (videoRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const imageData = canvas.toDataURL('image/png'); // Get image as data URL
            setCapturedImage(imageData); // Set the captured image
        }
    };

    // Cleanup the stream when the component unmounts
    useEffect(() => {
        return () => {
            if (stream) {
                stopCamera();
            }
        };
    }, [stream]);

    return (
        <div>
            <Header />
            <div className="camera-container" style={{ display: 'flex', gap: '20px' }}>
                <div>
                    <h2>Camera Access</h2>
                    <video ref={videoRef} autoPlay style={{ display: isCameraOn ? 'block' : 'none', width: '300px', height: 'auto' }} />
                    <button className='mirrorbtn' onClick={toggleCamera}>
                        {isCameraOn ? 'Close Camera' : 'Open Camera'}
                    </button>
                    {isCameraOn && (
                        <button className='mirrorbtn' onClick={captureImage} style={{ marginTop: '10px', marginLeft: '10px' }}>
                            Capture Image
                        </button>
                    )}
                </div>
                <div>
                    <h2>Captured Image</h2>
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    {capturedImage && <img src={capturedImage} alt="Captured" style={{ border: '1px solid #ccc', width: '300px' }} />}
                </div>
            </div>
        </div>
    );
};

export default CameraAccess;
