import React, { useEffect, useRef, useState } from 'react';
import './Slider.css';

const Slider = () => {
  const images = [
    "//myraymond.com/cdn/shop/files/Banner_3_2948f632-2bba-4933-a15e-3d1746afa43e.png?v=1724996983",
    "//myraymond.com/cdn/shop/files/website_banner_4.png?v=1725879064",
    "https://myraymond.com/cdn/shop/files/Banner_2_d3218c2c-b03b-4a89-a43b-1fc7bcbd2451.png?v=1724996942",
    "https://www.snitch.co.in/cdn/shop/files/3_WebBanner_1920x1080_1_1400x.jpg?v=1725093880"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderWrapperRef = useRef(null);

  const moveToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const moveToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(moveToNextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const offset = -currentIndex * 100;
    if (sliderWrapperRef.current) {
      sliderWrapperRef.current.style.transform = `translateX(${offset}vw)`;
    }
  }, [currentIndex]);

  return (
    <div className="slider-container">
      <button className="slider-btn left-btn" onClick={moveToPrevSlide}>
        &lt;
      </button>
      <div className="slider-wrapper" ref={sliderWrapperRef}>
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index + 1}`} />
        ))}
      </div>
      <button className="slider-btn right-btn" onClick={moveToNextSlide}>
        &gt;
      </button>
      <div className="slider-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
