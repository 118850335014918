import React from "react";
import "./ProductPage.css";

const products = [
  {
    id: 1,
    title: "Stylish Shirt",
    subtitle: "Comfortable and sustainable fabric",
    discountPrice: "$20.00",
    mainPrice: "$25.00",
    discount: "20% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/x/v/2/l-st10-vebnor-original-imah5z2ucegzdbdj.jpeg?q=70&crop=false",
  },
  {
    id: 2,
    title: "Casual T-Shirt",
    subtitle: "Eco-friendly and versatile design",
    discountPrice: "$15.00",
    mainPrice: "$18.00",
    discount: "15% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/l/9/g/m-goa144-combraided-original-imags8xcvyd3gugc.jpeg?q=70&crop=false",
  },
  {
    id: 3,
    title: "Formal Suit",
    subtitle: "Perfect for professional occasions",
    discountPrice: "$80.00",
    mainPrice: "$90.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/h/f/t/l-mens-staylist-chex-shirt-sti-original-imah3j6nqn7gvgsz.jpeg?q=70&crop=false",
  },
  {
    id: 4,
    title: "Men Regular Fit Self Design Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹2,247",
    mainPrice: "₹4,999",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/p/e/w/l-men-s-regular-lycra-casl-printuaed-half-sleeve-new-design-original-imagucffs6cyjmmx.jpeg?q=70&crop=false",
  },
  {
    id: 5,
    title: "Men Slim Fit Solid Spread Collar Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹1,798",
    mainPrice: "₹3,999",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/h/a/l-mens-staylist-chex-strecheble-shirt-dbex-original-imah3hj4zgtwt5bq.jpeg?q=70&crop=false",
  },
  {
    id: 6,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹621",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/g/j/t/s-mens-staylist-chex-strecheble-shirt-dbex-original-imah3hj4wrbnaujh.jpeg?q=70&crop=false",
  },
  {
    id: 7,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Dhoti ",
    discountPrice: "₹444",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/t/l/n/s-st52-vebnor-original-imah3chrvxtgtras.jpeg?q=70&crop=false",
  },
  {
    id: 8,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹450",
    mainPrice: "₹1,998",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/n/x/i/xl-st58-vebnor-original-imah5r3wxnpexspf.jpeg?q=70&crop=false",
  },
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="polo">
        <div className="hero-content">
          <h1>"Effortlessly stylish and endlessly versatile – elevate your wardrobe with classic polo shirts!"</h1>
          <p>
          "Comfort meets sophistication – perfect for every occasion, from casual outings to smart events."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

      <div className="product-section">
        {products.map((product) => (
          <div key={product.id} className="product">
            <div className="product-link">
              <img src={product.image} alt={product.title} className="product-image" />
              <h4 className="product-title">{product.title}</h4>
              <h3 className="sub-title">{product.subtitle}</h3>
              <div className="price">
                <h3 className="main-price">
                  {product.discountPrice} <span className="d-price">{product.mainPrice}</span>{" "}
                  <span className="s-discount">{product.discount}</span>
                </h3>
              </div>
            </div>
            <div className="button-group">
              <button className="cart-button add-to-cart-btn">Add To Cart</button>
              <button className="cart-button buy-now-btn">Buy Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
