import React, { useState } from "react";

const ImageModal = () => {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleOpenImage = () => {
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "-6%", marginLeft: "40%"  }}>
      {/* Open Image Button */}
      <button className="sizebtn" onClick={handleOpenImage} style={{ padding: "10px 20px", fontSize: "16px" , color: "white" , backgroundColor: "#444"}}>
        Size Chart
      </button>

      {/* Image Modal */}
      {isImageOpen && (
        <div
          style={{
            position: "fixed",
            top: "-10%",
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            {/* Close Button */}
            <button
              onClick={handleCloseImage}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>

            {/* Image */}
            <img
              src="https://bodyandsoul.mu/media/size_chart/menwebcom.jpg"
              alt="Example"
              style={{ width: "200px", height: "auto", borderRadius: "8px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
