// ProductsData.js
export const product = [
    {
        id: 4,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Ramraj Cotton",
        discountPrice: "Rs.658.00",
        mainPrice: "Rs.999",
        discount: "40% Off",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "WOXEN",
        discountPrice: "Rs.355.00",
        mainPrice: "Rs.2099",
        discount: "83% Off",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Slim Fit Checkered Slim Collar Casual Shirt#JustHere",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Tanip",
        discountPrice: "Rs.219.00",
        mainPrice: "Rs.1399",
        discount: "84% Off",
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Regular Fit Solid Mandarin Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "RAHUL LOOK",
        discountPrice: "Rs.269.00",
        mainPrice: "Rs.999",
        discount: "73% Off",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      }
  ];
  