import React from 'react';
import { Link } from 'react-router-dom';

const ProductList = () => {
    const products = [
        { id: 1, name: 'Product 1', price: 500, mainPrice: 700, image: 'https://example.com/product1.jpg' },
        { id: 2, name: 'Product 2', price: 600, mainPrice: 800, image: 'https://example.com/product2.jpg' },
        // Add more products as needed
    ];

    return (
        <div className="product-list">
            {products.map(product => (
                <div key={product.id} className="product-item">
                    <img src={product.image} alt={product.name} />
                    <h2>{product.name}</h2>
                    <p>₹{product.price}</p>
                    <Link to={`/product/${product.id}`} state={{ product }}>View Product</Link>
                </div>
            ))}
        </div>
    );
};

export default ProductList;
