import React from "react";
import "./ProductPage.css";

const products = [
  {
    id: 1,
    title: "Stylish Shirt",
    subtitle: "Comfortable and sustainable fabric",
    discountPrice: "$20.00",
    mainPrice: "$25.00",
    discount: "20% Off",
    image: "https://m.media-amazon.com/images/I/61qcRPTaTaL._SY879_.jpg",
  },
  {
    id: 2,
    title: "Casual T-Shirt",
    subtitle: "Eco-friendly and versatile design",
    discountPrice: "$15.00",
    mainPrice: "$18.00",
    discount: "15% Off",
    image: "https://i.pinimg.com/736x/f4/b7/c2/f4b7c20b965ce20ea0e8474b18e669e0.jpg",
  },
  {
    id: 3,
    title: "Formal Suit",
    subtitle: "Perfect for professional occasions",
    discountPrice: "$80.00",
    mainPrice: "$90.00",
    discount: "10% Off",
    image: "https://m.media-amazon.com/images/I/31o2yYU65GL.jpg",
  },
  {
    id: 4,
    title: "Men Regular Fit Self Design Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹2,247",
    mainPrice: "₹4,999",
    discount: "10% Off",
    image: "https://m.media-amazon.com/images/I/818PWML1m+L._AC_UY1100_FMwebp_.jpg",
  },
  {
    id: 5,
    title: "Men Slim Fit Solid Spread Collar Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹1,798",
    mainPrice: "₹3,999",
    discount: "10% Off",
    image: "https://i.pinimg.com/736x/a4/f8/ca/a4f8ca8f1db51b67785bf626093b189d.jpg",
  },
  {
    id: 6,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹621",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://i.pinimg.com/474x/8f/9b/0d/8f9b0da10f8a7e31a3e1fb8e6551ad81.jpg",
  },
  {
    id: 7,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Dhoti ",
    discountPrice: "₹444",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://i.pinimg.com/736x/f4/b7/c2/f4b7c20b965ce20ea0e8474b18e669e0.jpg",
  },
  {
    id: 8,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹450",
    mainPrice: "₹1,998",
    discount: "10% Off",
    image: "https://i.pinimg.com/custom_covers/200x150/95068310823109684_1484533254.jpg",
  },{
    id: 9,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },{
    id: 4,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "$40.00",
    mainPrice: "$45.00",
    discount: "10% Off",
    image: "https://via.placeholder.com/800x400",
  },
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="heroo">
        <div className="hero-content">
          <h1>"Rediscover Timeless Elegance with Vintage Classics"</h1>
          <p>"Celebrate the charm of classic styles that never go out of fashion. Relive the past with a modern touch."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

      <div className="product-section">
        {products.map((product) => (
          <div key={product.id} className="product">
            <div className="product-link">
              <img src={product.image} alt={product.title} className="product-image" />
              <h4 className="product-title">{product.title}</h4>
              <h3 className="sub-title">{product.subtitle}</h3>
              <div className="price">
                <h3 className="main-price">
                  {product.discountPrice} <span className="d-price">{product.mainPrice}</span>{" "}
                  <span className="s-discount">{product.discount}</span>
                </h3>
              </div>
            </div>
            <div className="button-group">
              <button className="cart-button add-to-cart-btn">Add To Cart</button>
              <button className="cart-button buy-now-btn">Buy Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
