import React from "react";
import "./ProductPage.css";

const products = [
  {
    id: 1,
    title: "Stylish Shirt",
    subtitle: "Comfortable and sustainable fabric",
    discountPrice: "$20.00",
    mainPrice: "$25.00",
    discount: "20% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/x/j/8/s-ts54-vebnor-original-imah5gge9j99cwwa.jpeg?q=70&crop=false",
  },
  {
    id: 2,
    title: "Casual T-Shirt",
    subtitle: "Eco-friendly and versatile design",
    discountPrice: "$15.00",
    mainPrice: "$18.00",
    discount: "15% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/t/k/l/xxl-ts61-vebnor-original-imah3ny7hkgrhkgg.jpeg?q=70&crop=false",
  },
  {
    id: 3,
    title: "Formal Suit",
    subtitle: "Perfect for professional occasions",
    discountPrice: "$80.00",
    mainPrice: "$90.00",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/r/g/x/s-ts28-vebnor-original-imah3dvdszjk6drp.jpeg?q=70&crop=false",
  },
  {
    id: 4,
    title: "Men Regular Fit Self Design Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹2,247",
    mainPrice: "₹4,999",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/h/1/b/s-ts17-vebnor-original-imah6f7ezx2hbrxv.jpeg?q=70&crop=false",
  },
  {
    id: 5,
    title: "Men Slim Fit Solid Spread Collar Formal Shirt",
    subtitle: "PARK AVENUE ",
    discountPrice: "₹1,798",
    mainPrice: "₹3,999",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/s/a/9/-original-imah23cpg53gsbga.jpeg?q=70&crop=false",
  },
  {
    id: 6,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹621",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/q/m/w/xxl-2002-teal-merriment-original-imagzh7kbhysrnzg.jpeg?q=70&crop=false",
  },
  {
    id: 7,
    title: "Sumit online solid Self Design Men Dhoti",
    subtitle: "Dhoti ",
    discountPrice: "₹444",
    mainPrice: "₹1,299",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/c/h/u/xxl-anremt6-tl-uniberry-original-imagw4s63g5agzzt.jpeg?q=70&crop=false",
  },
  {
    id: 8,
    title: "Denim Jacket",
    subtitle: "Made with sustainable denim",
    discountPrice: "₹450",
    mainPrice: "₹1,998",
    discount: "10% Off",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/9/w/t/xl-striped-men-polo-neck-multicolor-t-shirt-inkkr-original-imaggvqzn3z76ujb.jpeg?q=70&crop=false",
  },
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="polotshirt">
        <div className="hero-content">
          <h1>"Stay cool, stay classic – redefine your style with our premium polo t-shirts!"</h1>
          <p>"Where comfort meets class – perfect for everyday wear and beyond."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

      <div className="product-section">
        {products.map((product) => (
          <div key={product.id} className="product">
            <div className="product-link">
              <img src={product.image} alt={product.title} className="product-image" />
              <h4 className="product-title">{product.title}</h4>
              <h3 className="sub-title">{product.subtitle}</h3>
              <div className="price">
                <h3 className="main-price">
                  {product.discountPrice} <span className="d-price">{product.mainPrice}</span>{" "}
                  <span className="s-discount">{product.discount}</span>
                </h3>
              </div>
            </div>
            <div className="button-group">
              <button className="cart-button add-to-cart-btn">Add To Cart</button>
              <button className="cart-button buy-now-btn">Buy Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
