import React, { useEffect, useState } from 'react';
import './Watchlist.css';

const Watchlist = () => {
  const [watchlist, setWatchlist] = useState([]);

  // Fetch the watchlist from localStorage when the component mounts
  useEffect(() => {
    const storedWatchlist = JSON.parse(localStorage.getItem('watchlistItems')) || [];
    setWatchlist(storedWatchlist);
  }, []);

  // Handle item removal from the watchlist
  const removeFromWatchlist = (item) => {
    const updatedWatchlist = watchlist.filter((i) => i.id !== item.id);
    setWatchlist(updatedWatchlist);
    localStorage.setItem('watchlistItems', JSON.stringify(updatedWatchlist));
  };

  // Handle adding the item to the cart
  const addToCart = (item) => {
    const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Check if the item is already in the cart and update its quantity if so
    const existingItemIndex = storedCart.findIndex((cartItem) => cartItem.id === item.id);
    if (existingItemIndex !== -1) {
      storedCart[existingItemIndex].quantity += 1;  // Increase quantity if item already exists
    } else {
      item.quantity = 1;  // Set default quantity if item is new
      storedCart.push(item);
    }

    // Save the updated cart back to localStorage
    localStorage.setItem('cartItems', JSON.stringify(storedCart));

    // Trigger update for cart count (optional if you're using the 'storage' event)
    window.dispatchEvent(new Event('storage'));

    alert(`${item.title} has been added to your cart!`);
  };

  return (
    <div className="watchlist-container">
      <h2>Your Watchlist</h2>
      {watchlist.length === 0 ? (
        <p>No items in your watchlist</p>
      ) : (
        <ul>
          {watchlist.map((item) => (
            <li key={item.id} className="watchlist-item">
              <img
                src={item.image || item.images?.[0]}
                alt={item.title}
                className="watchlist-item-image"
              />
              <div className="watchlist-item-details">
                <h3>{item.title}</h3>
                <p>{item.subtitle}</p>
                <p>{item.description}</p>
                <div className="price-section">
                  <span className="discount-price">{item.discountPrice}</span>
                  <span className="main-price">{item.mainPrice}</span>
                  <span className="discount">{item.discount}</span>
                </div>
                <button className='remove-button' onClick={() => removeFromWatchlist(item)}>Remove</button>
                <button className='add-to-carta' onClick={() => addToCart(item)}>Add to Cart</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Watchlist;
